import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BsClockHistory, BsClock, BsPeople } from "react-icons/bs"
import Layout from "../../components/Layout"
import slugify from "slugify"
import deChar from "../../utils/deChar"
import RecomIdeetipp from "../../components/RecomIdeetipp"
import SEO from "../../components/SEO"

const InspirationTemplate = ({ data }) => {
  const {
    product_name,
    aw_image_url,
    ean,
    merchant_name,
    aw_deep_link,
    description,
    /*category_name,*/
    merchant_image_url,
    /*merchant_product_second_category,*/
    average_rating,
    aw_product_id,
    /*aw_thumb_url,*/
    brand_name,
    /*category_id,*/
    colour,
    /*custom_1,*/
    merchant_product_category_path,
  } = data.produkteKurzJson

  const pathToImage = getImage(aw_image_url)

  return (
    <Layout>
      <SEO title={product_name} description={description || "-"} />

      <main className="page">
        <div className="recipe-page">
          {/* hero */}
          <section className="recipe-hero">
            <article className="recipe-info">
              <img
                src={aw_image_url
                  .replace("w=200", "w=500")
                  .replace("h=200", "h=500")}
                alt={product_name}
                className="about-img"
              />
            </article>
            <article className="recipe-info">
              Jetzt beim Partner entdecken
              <h1>{product_name}</h1>
              {merchant_product_category_path} <br />
              Marke: {brand_name} <br />
              <p>Bei: {merchant_name}</p>
              <h5>Werbung</h5>
              {/*icons
              <div className="recipe-icons">
                <article>
                  <BsClock />
                  <h5>Vorbereitungszeit</h5>
                  <p>-</p>
                </article>
                <article>
                  <BsClockHistory />
                  <h5>Gesamtdauer</h5>
                  <p>-</p>
                </article>
                <article>
                  <BsPeople />
                  <h5>Personen</h5>
                  <p>-</p>
                </article>
              </div>*/}
            </article>
          </section>
          {/* Button */}
          <article className="recipe-content">
            <a
              href={aw_deep_link}
              target="_blank"
              rel="noopener noreferrer sponsored"
            >
              <button className="btn-aff">Jetzt entdecken</button>
            </a>
          </article>
          {/* rest of content */}
          <section className="recipe-content">
            <article>
              <div>
                <h4>Beschreibung</h4>
                <h5>{product_name}</h5>
                {description}
              </div>
            </article>
            <article className="second-column">
              <div>
                <h4>Details</h4>
                <p>
                  Bewertung: {average_rating && average_rating.toFixed(1)} / 5.0{" "}
                  <br />
                  EAN: {ean} <br />
                  Farbe: {colour} <br />
                </p>
              </div>

              <p></p>
            </article>
          </section>
          <section className="featured-recipes">
            <h5>Entdecke weitere, interessante Tipps und Ideen</h5>
            <RecomIdeetipp />
          </section>
        </div>
      </main>
    </Layout>
  )
}
export const data = graphql`
  query getSingleInsp($aw_product_id: Float) {
    produkteKurzJson(aw_product_id: { eq: $aw_product_id }) {
      average_rating
      aw_deep_link
      aw_image_url
      aw_product_id
      brand_name
      colour
      description
      ean
      merchant_name
      product_name
      merchant_product_category_path
    }
  }
`
export default InspirationTemplate
/*
merchant_product_second_category
custom_1 
category_id
category_name
aw_thumb_url
*/